// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts
/* saira-regular - latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/saira/saira-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Saira Regular'), local('Saira-Regular'),
       url('../fonts/saira/saira-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira/saira-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira/saira-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira/saira-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira/saira-v5-latin-regular.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-500 - latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/saira/saira-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Saira Medium'), local('Saira-Medium'),
       url('../fonts/saira/saira-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira/saira-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira/saira-v5-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira/saira-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira/saira-v5-latin-500.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-800 - latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/saira/saira-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Saira ExtraBold'), local('Saira-ExtraBold'),
       url('../fonts/saira/saira-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira/saira-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira/saira-v5-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira/saira-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira/saira-v5-latin-800.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-700 - latin */
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/saira/saira-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Saira Bold'), local('Saira-Bold'),
       url('../fonts/saira/saira-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira/saira-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira/saira-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira/saira-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira/saira-v5-latin-700.svg#Saira') format('svg'); /* Legacy iOS */
}


@font-face{
    font-family:"marguerite-regular";
    font-display: swap;
    src:url("../fonts/marguerite/marguerite.ttf") format("woff"),
    url("../fonts/marguerite/marguerite.ttf") format("opentype"),
    url("../fonts/marguerite/marguerite.ttf") format("truetype");
}


//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Saira", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: "marguerite-regular", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1-normal { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1-normal; font-weight: 300; }
.font-1-medium { @extend .font-1-normal; font-weight: 500; }
.font-1-bold { @extend .font-1-normal; font-weight: 700; }
// Libre Baskerville
.font-2-normal { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2-normal; font-weight: 700; }



// MAIN FONTS
.font-main {
    @extend .font-1-normal;
    @extend .text-color;
    font-size: $font-size-20;
}


// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-1-medium;
    font-size: $font-size-24;
    line-height: 1;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-1-normal;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-1-normal;
    font-size: $font-size-24;
}
.font-nav-lang {
  @extend .font-1-normal;
  font-size: $font-size-18;
}
// si le logo est du texte
.font-nav-logo {
    @extend .font-2-normal;
    font-size: $font-size-28;
    //@media screen and (max-width: $size-sm-max){
    //    font-size: $font-size-20;
    //}
    //@media screen and (max-width: $size-xs-max){
    //    font-size: $font-size-14;
    //}
}

// FOOTER FONTS

.font-footer {
    @extend .font-1-normal;
    font-size: $font-size-14;
}
