// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

.border-left-bigger {
  clip-path: polygon(0 0, 100% 10vw, 100% 90%, 0 100%);
}
.border-right-bigger {
  clip-path: polygon(0 5vw, 100% 0, 100% 100%, 0 95vw);
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

@import 'sections/section-bursts2';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';



/*****************************************************/
/* POP-UP
/*****************************************************/

.popup-box {
  h3 {
    font-family: "marguerite-regular" !important;
    font-weight: 400;
    color: #bd1220;
    text-transform: initial;
    padding-bottom: 15px;
  }
  p {
    padding: 0;
  }
}

/*****************************************************/
/* INDEX.PHP
/*****************************************************/
section.section-01-black {
    padding: 75px 5% 210px 5%;
    background-color: $color-black;
    div.title-box{
      padding-left: 40px;
    }
    div.text-box {
        padding-top: 20px;
    }
    h3 {
        @extend .font-2-normal;
        color: $color-1;
        font-size: 80px;
        text-transform: initial;
    }
    p {
        color: $color-white;
        text-align: justify;
        font-size: $font-size-24;
    }
    @media screen and (max-width: $size-xs-max) {
          padding: 100px 5%;
          div.text-box {
            padding: 10px 0;
          }
          h3 {
              font-size: 40px;
              text-align: center;
          }
          p {
              font-size: 18px;
          }
    }
}

section.gallery-section-accueil {
    background-image: url('../images/accueil_section02_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 200px 0;
    margin-top: -10vw;
    z-index: 3;
    @media screen and (max-width: 768px){
        padding: 80px 0;
    }
    a.logo-titre {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 50px;
      .logo {
        padding-right: 4%;
      }
      .titre {
        h4 {
            font-size: $font-size-30;
            font-weight: 700;
            color: #000;
        }
      }
      :hover {
        h4 {
          color: #bd1220;
        }
      }
    }
    div.iframe-wrapper {
          position: relative;
          iframe {
            height: 800px;
            @media screen and (max-width: 1200px){
                height: 635px;
                @media screen and (max-width: 991px){
                    height: 490px;
                    @media screen and (max-width: 768px){
                        height: calc(100vw * 2 / 3);
                    }
                }
            }
          }
          .iframe-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
          }
    }
}

div.section-wrapper {
    position: relative;
    margin-top: -10vw;
    clip-path: polygon(0 20%, 100% 0, 100% 162%, 0 100%);
    .image-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 40%;
        //height: 600px;
        transform: translateX(-50%);

    }
    @media screen and (max-width: 500px){
        .image-wrapper {
          display: none;
        }
        margin-top: -20vw;
    }
}

section.section-diagonal {
    clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
    background-color: $color-black;
    background-image: radial-gradient(
                      ellipse closest-side,
                      rgba(255, 255, 255, .2),
                      #000
                    );
    height: 33vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div.text-box {
        width: 40%;
        position: absolute;
        left: 10%;
        p {
            color: $color-white;
        }
        h3 {
          @extend .font-2-normal;
          color: $color-1;
          font-size: 4vw;
          padding-right: 30px;
        }
    }
    @media screen and (max-width: $size-sm-max){
        height: 400px;
        justify-content: center;
        div.text-box {
          text-align: center;
            p {
                br {display: none;}
            }
        }
        @media screen and (max-width: 600px){
          div.text-box {
              width: 60%;
              text-align: center;
            }
        }
    }
}

section.section-rdv {
  background-image: url('../images/accueil_section03_bg.jpg');
  padding: 180px 5% 340px 2%;
  margin-top: -135px;
  background-size: cover;
  .text-box {
    h3 {
      font-family: "marguerite-regular" !important;
      color: #fff;
      text-transform: initial;
      font-size: $font-size-64;
      text-align: right;
      line-height: 1.4;
      padding-bottom: 15px;
    }
    h4 {
      font-family: "marguerite-regular" !important;
      color: #bd1220;
      text-transform: initial;
      font-size: $font-size-64;
      text-align: right;
    }
  }
  .button-box {
    display: flex;
    flex-direction: column;
    width: 18%;
    position: absolute;
    right: 5%;
    padding-top: 50px;
    a.btn-rdv {
      background: #bd1220;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: $font-size-20;
      padding: 20px 10px;
      &:hover {
        color: #000;
      }
    }
    a.btn-rdv.first {
      margin-bottom: 20px;
    }
  }
}

section.section-footer-links {
      padding: 80px 5% 0 5%;
      background-color: $color-black;
      h3 {
        font-family: "marguerite-regular" !important;
        color: #bd1220;
        text-transform: initial;
        font-size: $font-size-80;
      }
      .bloc-footer {
        display: flex;
        padding-top: 50px;
        padding-left: 6%;
        padding-bottom: 35px;
        border-bottom: 1px solid #fff;
        div.list-wrapper.first {
          margin-right: 5%;
        }
        div.list-wrapper {
            width: 900px;
            .icon-titre {
              display: flex;
              .icon {
                padding-right: 25px;
              }
              .titre {
                h4 {
                  padding: 20px 0;
                  color: #bd1220;
                  font-size: $font-size-24;
                  text-transform: uppercase;
                  font-weight: 500;
                }
              }
            }
        a {
          display: block;
          transition: .3s;
          font-size: $font-size-24;
          line-height: 1.5;
          color: #fff;
            i {
              padding-right: 15px;
              color: $color-1;
            }
            &:hover {
              transition: .3s;
              color: $color-grey;
            }
          }
          .jour-heure {
            display: flex;
            .jour {
              width: 35%;
            }
            p {
              font-weight: 500;
              color: #fff;
              font-size: $font-size-24;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      @media screen and (max-width: 1200px){
        div.container-fluid.row {
          justify-content: center;
        }
      }
      @media screen and (max-width: $size-sm-max){
          background-position: center;
          div.container-fluid.row {
            justify-content: center;
          }
          div.list-wrapper {
              width: 100%;
              max-width: 500px;
              a {
                font-size: 15px;
              }
          }
      }

}


/*******************************************************************************
* TATOUAGES & PERCING
*******************************************************************************/

section#section-titre_tatouage {
  background: url("../images/tatouages_section01_bg.jpg") no-repeat;
  height: 524px;
  padding: 150px 3%;
  .blocInfo {
    position: relative;
    left: 52%;
    bottom: 45%;
    .coordonnees, .heures {
      display: flex;
      align-items: flex-start;
      p {
        font-size: $font-size-24;
        font-weight: 500;
        color: #fff;
        padding-left: 15px;
        line-height: 1;
      }
    }
  }
  .blocIntro {
    text-align: center;
    padding: 10px;
    p {
      font-size: $font-size-24;
      font-weight: 500;
      span {
        text-align: center;
      }
    }
  }
}

section#section-artistes {
  padding: 25px 5%;
  h2 {
    font-size: $font-size-80;
    font-family: $font-family-2;
    color: #000;
  }
  .bloc {
    margin: 35px 5%;
    border-bottom: 3px solid #e6e6e6;
    &:last-child {
      border-bottom: none;
    }
    .image-texte {
      display: flex;
      .image {
        width: 60%;
        background: #000;
        clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
        margin-right: 60px;
        display: flex;
        position: relative;
        height: 455px;
        .img-responsive {
          margin: auto;
        }
        .logo-insta {
          position: absolute;
          left: 4%;
          bottom: 7%;
            img.hover {
              display: none;
            }
          &:hover {
            img.hover {
              display: block;
            }
            img.defaut {
              display: none;
            }
          }
        }
      }
      .texte {
        width: 100%;
      }
      h3 {
        font-size: $font-size-36;
        font-weight: 500;
        padding-bottom: 20px;
        color: #000;
        text-transform: initial;
      }
      p {
        font-size: $font-size-24;
        font-weight: 500;
        padding-bottom: 5px;
      }
    }

    .blocRealisations {
      padding-top: 100px;
      padding-bottom: 55px;
      h3.titre-realisation {
        font-size: $font-size-36;
        font-weight: 500;
        padding-bottom: 50px;
        color: #000;
        text-transform: initial;
        text-align: center;
      }
      .images.flex {
        justify-content: space-between;
        align-items: center;
      }
      .full-image {
        display: none;
        .images.flex {
          justify-content: center;
        }
        .image-full {
            padding: 20px 20px 35px 20px;
        }
      }
      a.btnPlus {
        font-size: $font-size-24;
        font-weight: normal;
        color: #fff;
        cursor: pointer;
        border-radius: 10px;
        background: #000;
        padding: 15px 25px;
        display: -webkit-inline-box;
        margin-top: 50px;
      }
      a.btnPlus:hover {
        color: #bd1220;
      }
      a.btnMoins {
        font-size: $font-size-24;
        font-weight: normal;
        color: #fff;
        cursor: pointer;
        border-radius: 10px;
        background: #000;
        padding: 15px 25px;
        display: -webkit-inline-box;
        margin-top: 50px;
      }   
      a.btnMoins:hover {
        color: #bd1220;
      }
    }
  }
}

section#section-contact {
  background: url("../images/tatouages_section03_bg.jpg") no-repeat;
  background-size: cover;
  padding: 200px 10% 55px 5%;
  a.lienFacebook {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left-side {
      h3 {
        font-size: $font-size-70;
        font-family: $font-family-2;
        color: #bd1220;
        padding-bottom: 25px;
        text-transform: initial;
        span {
          font-family: $font-family-1;
          color: #fff;
          font-size: $font-size-48;
          font-weight: 500;
        }
      }
    }
    .right-side {
      padding-bottom: 10px;
      img.hover {
        display: none;
      }
    }
    &:hover {
      img.hover {
        display: block;
      }
      img.defaut {
        display: none;
      }
    }
  }
}


/*******************************************************************************
* FAQ
*******************************************************************************/

section#section-titre {
  background: url("../images/faq_section01_bg.jpg") no-repeat;
  height: 415px;
  padding: 150px 3%;
  h2 {
    font-size: $font-size-80;
    font-family: $font-family-2;
  }
}

section#section-questions {
  padding: 100px 5%;
  .bloc {
    padding-bottom: 75px;
    &:last-child {
      padding-bottom: 0;
    }
    h3 {
      font-size: $font-size-48;
      color: #000;
      font-weight: 500;
      padding-bottom: 20px;
      text-transform: initial;
    }
    p {
      font-size: $font-size-24;
      font-weight: 500;
    }
  }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1717px) {

section.section-rdv .button-box a.btn-rdv {
    font-size: 0.9rem;
    padding: 20px 5px;
}
}

@media screen and (max-width: 1700px) {

section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 1rem;
}
section.section-footer-links .bloc-footer div.list-wrapper .icon-titre .titre h4 {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 1695px) {

section.section-footer-links .bloc-footer {
    padding-left: 4%;
}
}

@media screen and (max-width: 1645px) {
    
section#section-titre_tatouage .blocInfo {
    left: 45%;
}
section#section-artistes .bloc {
    margin: 35px 2%;
}
section#section-titre_tatouage .blocIntro p br {
  display: none;
}
}

@media screen and (max-width: 1550px) {
    
section#section-artistes .bloc .blocRealisations .images .image-small {
    width: 22%;
}
.bursts .bursts-container .burst p {
    padding: 0;
    margin: 0;
}
}

@media screen and (max-width: 1510px) {

section.section-rdv .button-box {
    width: 20%;
}
}

@media screen and (max-width: 1450px) {
    
section#section-titre_tatouage .blocInfo {
    left: 38%;
}
section#section-artistes .bloc .image-texte .image .logo-insta {
    left: 2%;
    bottom: 4%;
}
}

@media screen and (max-width: 1390px) {

section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 0.9rem;
}
section.section-footer-links .bloc-footer div.list-wrapper.first {
    margin-right: 10%;
}
section.section-footer-links .bloc-footer div.list-wrapper .jour-heure .jour {
    width: 40%;
}
footer .footer-wrapper .copyright, footer .footer-wrapper .mediaweb {
    width: 20%;
}
}

@media screen and (max-width: 1355px) {

section.section-rdv .button-box {
    width: 25%;
}
}

@media screen and (max-width: $size-md-max) {
    
section#section-artistes .bloc .image-texte .image {
    clip-path: none;
    padding: 30px;
    height: 395px;
    width: 50%;
}
section#section-artistes .bloc .image-texte .image .img-responsive {
    margin: 0;
}
section#section-titre_tatouage {
    padding: 125px 3%;
}
section#section-titre_tatouage .blocInfo {
    bottom: 30%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li.dropdown a.menu-title {
    font-size: 1.2rem;
}
#section-artistes .bloc .image .logo-insta {
    width: 30px;
}
section#section-contact a.lienFacebook .right-side {
    padding-bottom: 25px;
    width: 80px;
}
section#section-artistes .bloc .blocRealisations {
    padding-top: 60px;
}
}

@media screen and (max-width: 1185px) {

section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 0.8rem;
}
}

@media screen and (max-width: 1024px) {
   
section#section-titre_tatouage {
    padding: 110px 3%;
}
section#section-titre_tatouage .blocInfo {
    bottom: 22%;
    left: 25%;
} 
section#section-artistes .bloc .image-texte .image {
    height: 333px;
}
section#section-artistes .bloc .image-texte h3.titre-realisation {
    padding-top: 0px;
}
section#section-titre {
    height: 370px;
}
section#section-questions {
    padding: 50px 5%;
}
section.section-rdv {
    padding: 230px 5% 245px 2%;
}
section.section-diagonal div.text-box {
    left: 4%;
    bottom: 35%;
}
section.section-01-black {
    padding: 75px 5% 120px 5%;
}
section.section-footer-links .bloc-footer {
    padding-left: 0;
}
footer .footer-wrapper .copyright, footer .footer-wrapper .mediaweb {
    width: 25%;
}
section#section-contact {
    padding: 200px 5% 55px 5%;
}
}

@media screen and (max-width: $size-sm-max) {
   
nav.module-menu #menu-mobile ul li .submenu ul li.subitem a div {
    font-size: 1rem;
} 
section#section-titre_tatouage .blocInfo {
    bottom: 25%;
    left: 10%;
} 
section#section-artistes .bloc .image-texte .image {
    height: 270px;
}
#section-form .section-form {
    position: relative;
    right: 20px;
}
section#section-form div#right {
    position: initial;
}
section#section-artistes .bloc .image-texte h3 {
    padding-bottom: 0;
}
section.section-diagonal {
    height: 340px;
}
section.section-diagonal div.text-box {
    width: 31%;
}
div.section-wrapper .image-wrapper {
  left: 60%;
}
section.section-rdv .button-box {
    width: 30%;
}
section.section-footer-links .bloc-footer {
    flex-direction: column;
}
section.section-footer-links .bloc-footer div.list-wrapper.first {
    margin-right: 0;
    padding-bottom: 15px;
}
section.section-footer-links .bloc-footer div.list-wrapper {
    width: 100%;
}
section.section-footer-links .bloc-footer div.list-wrapper .icon-titre .titre h4 {
    font-size: 1.2rem;
}
section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 1.1rem;
}
section.section-footer-links .bloc-footer div.list-wrapper.first {
    padding-bottom: 20px;
}
section.section-footer-links .bloc-footer div.list-wrapper .jour-heure .jour {
    width: 50%;
}
section#section-artistes .bloc .image-texte {
  flex-direction: column;
}
section#section-artistes .bloc .image-texte .image .logo-insta {
  bottom: 3%;
}
section#section-artistes .bloc .blocRealisations .images .image-small {
  width: 50%;
  padding: 10px;
}
section#section-artistes .bloc .blocRealisations .images.flex {
  flex-wrap: wrap;
}
section#section-artistes .bloc .blocRealisations {
  padding-top: 45px; 
}
section#section-artistes .bloc .image-texte .image {
    height: 400px;
    margin: auto;
    margin-bottom: 20px;
}
section#section-artistes .bloc .blocRealisations {
    padding-top: 0;
}
section#section-contact a.lienFacebook {
    flex-direction: column-reverse;
}
section#section-contact {
    padding: 100px 5% 55px 5%;
}
section#section-contact a.lienFacebook .right-side {
    padding-bottom: 30px;
    margin: auto;
}
#section-artistes .bloc .blocRealisations .small-image .images a, #section-artistes .bloc .blocRealisations .full-image .images a {
    width: 50%;
}
}

@media screen and (max-width: $size-xs-max) {
    
section#section-titre_tatouage {
  padding: 45px 3%;
  height: 460px;
}
section#section-titre_tatouage .blocInfo {
  bottom: 5%;
  left: 0%;
}
section#section-artistes .bloc .image-texte .image {
  height: 300px;
  margin: 0 auto 30px auto;
  width: 80%;
}
#section-form .section-form {
  position: initial;
}
section#section-form .form-courriel {
  position: initial;
}
section#section-form h3 {
  padding-left: 15px;
}
section#section-titre {
  height: 345px;
  padding: 125px 5%;
}
section#section-artistes .bloc .blocRealisations .full-image .image-full {
  width: 100%;
}
div#small_image_1 {
    padding-bottom: 30px;
}
section.section-diagonal div.text-box {
    width: 90%;
    position: initial;
}
div.section-wrapper {
    clip-path: none;
}
section.section-diagonal {
    clip-path: none;
}
section.section-diagonal div.text-box {
    width: 90%;
    position: initial;
}
section.section-diagonal div.text-box p {
    font-size: 1.2rem;
    padding: 0;
    margin: 0; 
}
section.section-diagonal {
    height: 250px;
}
section.section-rdv {
    padding: 75px 5%;
    background-position: right;
    margin-top: 0;
}
section.section-rdv .button-box {
    width: 55%;
    position: initial;
}
section.section-rdv .text-box h3 br {
  display: none;
}
section.section-rdv .text-box h3, section.section-rdv .text-box h4 {
  text-align: left;
}
div.section-wrapper {
    margin-top: 0;
}
section.gallery-section-accueil {
    padding: 50px 0;
}
.border-left-bigger {
    clip-path: none;
}
section.section-01-black {
    padding: 75px 5%;
}
section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 0.9em;
}
footer .footer-wrapper .copyright, footer .footer-wrapper .mediaweb {
    width: 50%;
    padding-bottom: 10px;
}
footer .footer-wrapper {
    flex-direction: column-reverse;
}
footer .footer-wrapper .bloc-logo {
    display: flex;
    position: relative;
    right: 37%;
    top: 12px;
    padding-bottom: 45px;
}
section.section-footer-links .bloc-footer div.list-wrapper .icon-titre {
    padding-bottom: 10px;
}
section#section-artistes h2 {
    font-size: 3.5rem;
    padding-left: 15px;
}
section#section-contact a.lienFacebook .left-side h3 {
    text-align: center;
}
}

@media screen and (max-width: 360px) {

section#section-titre_tatouage {
    padding: 40px 3%;
}
section#section-titre_tatouage .blocIntro p {
    font-size: 1.1rem;
}
section#section-artistes .bloc .image-texte .image {
    width: 93%;
}
section#section-form div#right .btn {
    position: relative;
    right: 20px;
}
section#section-form h3 {
    font-size: 3.3rem;
}
section#section-artistes .bloc .blocRealisations {
    padding-bottom: 0;
}
section#section-titre_tatouage .blocInfo .coordonnees p, section#section-titre_tatouage .blocInfo .heures p {
    font-size: 1.1rem;
    line-height: 1.2;
}
section.section-rdv .text-box h3, section.section-rdv .text-box h3, section.section-rdv .text-box h4 {
    font-size: 2.5rem;
}
section.section-rdv .button-box {
    width: 65%;
}
section.section-01-black p {
    font-size: 17px;
}
section.section-footer-links .bloc-footer div.list-wrapper .jour-heure p, section.section-footer-links .bloc-footer div.list-wrapper a {
    font-size: 0.8em;
}
section#section-contact {
    padding: 100px 3% 55px 3%;
}
}